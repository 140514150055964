// Redux store.js
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./Slices/AuthSlice";
import productReducer from "./Slices/productSlice";
import adCreateReducer from "./Slices/AdCreat";
import chateReducer from "./Slices/ChatSlice";
import bannerReducer from "./Slices/bannerSlice";
import searchReducer from "./Slices/SearchSlice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  chatObject: persistReducer(persistConfig, chateReducer),
  product: productReducer,
  adCreate: adCreateReducer,
  banner: bannerReducer,
  searchFilter: searchReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

const persistor = persistStore(store);

export { store, persistor };
