import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bannerData: {},
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    // Updating bannerData
    bannerGet: (state, action) => {
      state.bannerData = action.payload;
    },
  },
});

export const { bannerGet } = bannerSlice.actions;

export const selectBannerData = (state) => state.banner.bannerData;

export default bannerSlice.reducer;
