import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: "",
};

const searchSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    // Updating data
    searchfilter: (state, action) => {
      state.data = action.payload;
    },
    clearSearchFilter: (state) => {
      state.data = "";
    },
  },
});

export const { searchfilter ,clearSearchFilter } = searchSlice.actions;

export const selectSearchdata = (state) => state.searchFilter.data;

export default searchSlice.reducer;
