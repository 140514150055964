import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preimumProducts: [],
  locationPremium: false,
  saleProducts: [],
  AllpreimumProducts: [],
  locationAllPremium: false,
  locationAll: false,
  paginationPremium: false,
  allProducts: [],
  freeProducts: [], // Ensure this is an array
  locationFree: false,
  locationSale: false,
  prevFilters: [],
  filterProducts: [],
  isFilterData: false,
  isSearchFilter: false,
  allpagination: false,
  apiDataFilter: {},
  allBlog: [],
  allBlogPagination: false,
  addressFillter: {},
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // preimum data
    premimuProduct: (state, action) => {
      state.preimumProducts.push(action.payload);
    },

    preimumLike: (state, action) => {
      const { index } = action.payload;
      const productIndex = state.preimumProducts.findIndex(
        (product) => product.posts && product.posts[index]
      );
      if (productIndex !== -1) {
        state.preimumProducts[productIndex].posts[index].likes =
          !state.preimumProducts[productIndex].posts[index].likes;
      }
    },

    clearPremiumProducts: (state) => {
      state.preimumProducts = [];
    },

    setLocationPremium: (state, action) => {
      state.locationPremium = action.payload;
    },
    // sale products start

    saleProduct: (state, action) => {
      state.saleProducts = action.payload;
    },

    setLocationSale: (state, action) => {
      state.locationSale = action.payload;
    },

    saleLikes: (state, action) => {
      const { index } = action.payload;
      state.saleProducts.posts[index] = {
        ...state.saleProducts.posts[index],
        likes: !state.saleProducts.posts[index].likes,
      };
    },
    clearSaleProducts: (state) => {
      state.saleProducts = [];
    },
    // sale products ended

    // all premium products starts

    AllpremimuProduct: (state, action) => {
      state.AllpreimumProducts.push(action.payload);
    },

    clearAllPremiumProducts: (state) => {
      state.AllpreimumProducts = [];
    },

    AllpreimumLike: (state, action) => {
      const { index } = action.payload;
      const productIndex = state.AllpreimumProducts.findIndex(
        (product) => product.posts && product.posts[index]
      );
      if (productIndex !== -1) {
        state.AllpreimumProducts[productIndex].posts[index].likes =
          !state.AllpreimumProducts[productIndex].posts[index].likes;
      }
    },

    setLocatioAllPremium: (state, action) => {
      state.locationAllPremium = action.payload;
    },

    allPreimumPagination: (state, action) => {
      state.paginationPremium = action.payload;
    },
    clearAllProducts: (state) => {
      state.AllpreimumProducts = [];
    },

    setLocationAll: (state, action) => {
      state.locationAll = action.payload;
    },

    // end all premium products
    // free data

    freeProduct: (state, action) => {
      state.freeProducts = action.payload;
    },

    setLocationFree: (state, action) => {
      state.locationFree = action.payload;
    },

    // for object dataa llike

    freeLikes: (state, action) => {
      const { index } = action.payload;
      state.freeProducts.posts[index] = {
        ...state.freeProducts.posts[index],
        likes: !state.freeProducts.posts[index].likes,
      };
    },
    clearFreeProducts: (state) => {
      state.freeProducts = [];
    },

    //

    // all products data

    allProduct: (state, action) => {
      state.allProducts = action.payload;
    },

    toggleLike: (state, action) => {
      const { index } = action.payload;
      state.allProducts.posts[index] = {
        ...state.allProducts.posts[index],
        likes: !state.allProducts.posts[index].likes,
      };
    },

    setAllPagination: (state, action) => {
      state.allpagination = action.payload;
    },

    prevFilter: (state, action) => {
      state.prevFilters = action.payload;
    },

    // filter data
    filterProduct: (state, action) => {
      state.filterProducts = action.payload;
    },
    setIsFilter: (state, action) => {
      state.isFilterData = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.isSearchFilter = action.payload;
    },

    clearFilterProducts: (state) => {
      state.filterProducts = [];
    },

    filterLikes: (state, action) => {
      const { index } = action.payload;
      state.filterProducts.posts[index] = {
        ...state.filterProducts.posts[index],
        likes: !state.filterProducts.posts[index].likes,
      };
    },
    filterDataGet: (state, action) => {
      state.apiDataFilter = action.payload;
    },

    // filter ended

    // blog

    setBlog: (state, action) => {
      state.allBlog = action.payload;
    },
    setBlogPagination: (state, action) => {
      state.allBlogPagination = action.payload;
    },
    resetProductState: (state) => {
      return initialState;
    },

    // address filter

    addressGet: (state, action) => {
      state.addressFillter = action.payload;
    },

    clearAddress: (state) => {
      state.addressFillter = {};
    },
  },
});

export const {
  premimuProduct,
  setLocationPremium,
  AllpremimuProduct,
  setLocatioAllPremium,
  setLocationAll,
  allPreimumPagination,
  preimumLike,
  AllpreimumLike,
  allProduct,
  setAllPagination,
  freeProduct,
  saleProduct,
  setLocationFree,
  setLocationSale,
  freeLikes,
  saleLikes,
  toggleLike,
  prevFilter,
  filterProduct,
  filterLikes,
  setIsFilter,
  setSearchFilter,
  filterDataGet,
  addressGet,
  setBlog,
  setBlogPagination,
  resetProductState,
  // clear products
  clearPremiumProducts,
  clearFreeProducts,
  clearSaleProducts,
  clearFilterProducts,
  clearAllProducts,
  clearAllPremiumProducts,
  clearAddress,
} = productSlice.actions;

// premium products
export const selectPreimumProducts = (state) => state.product.preimumProducts;
export const selectLocationPremium = (state) => state.product.locationPremium;

// all products premium
export const selectAllPreimumProducts = (state) =>
  state.product.AllpreimumProducts;
export const selectPremiumPagination = (state) =>
  state.product.paginationPremium;

export const selectLocationAllPremium = (state) =>
  state.product.locationAllPremium;

// free products
export const selectFreeProducts = (state) => state.product.freeProducts;
export const selectSaleProducts = (state) => state.product.saleProducts;
export const selectLocationFree = (state) => state.product.locationFree;
export const selectLocationSale = (state) => state.product.locationSale;

// set addres fields
export const selectAddress = (state) => state.product.addressFillter;
// all products
export const selectAllProducts = (state) => state.product.allProducts;
export const selectAllProductsPagination = (state) =>
  state.product.allpagination;
export const selectLocationAll = (state) => state.product.locationAll;
// filter products
export const selectFilterProducts = (state) => state.product.filterProducts;
export const selectPrevFilterProducts = (state) => state.product.prevFilters;
export const selectFilterData = (state) => state.product.isFilterData;
export const selectSearchFilter = (state) => state.product.isSearchFilter;
// blog section
export const selectBlogData = (state) => state.product.allBlog;
export const selectAllBlogPagination = (state) =>
  state.product.allBlogPagination;

export default productSlice.reducer;
