import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adData: {},
};

const adCreateSlice = createSlice({
  name: "adCreate",
  initialState,
  reducers: {
    // Updating adData
    adDataCreate: (state, action) => {
      state.adData = action.payload;
    },
  },
});

export const { adDataCreate } = adCreateSlice.actions;

export const selectAdData = (state) => state.adCreate.adData;

export default adCreateSlice.reducer;
