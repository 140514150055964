import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatCount: {},
};

const chatSlice = createSlice({
  name: 'chatObject',
  initialState,
  reducers: {
    // Updating chatCount
    userChat: (state, action) => {
      state.chatCount = action.payload;
    },
  },
});

export const { userChat } = chatSlice.actions;

export const selectChatUser = (state) => state.chatObject.chatCount;

export default chatSlice.reducer;
